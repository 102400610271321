// add custom sass in this file

.site_title {
    padding: 1.2rem 2.5rem;
    font-size: 1.8rem;
    align-items: center;
    position: relative;
    z-index: 5;
    display: block;
    transition: background 0.3s ease-out;
    margin: 0; }

@media screen and (max-width: 769px) {
  .site_title {
      display: none; } }

@media screen and (max-width: 1024px) {
  .site_title {
    padding: 1.0rem 1.5rem;
    font-size: 1.2rem; } }

input.search-input {
    height: 1.7rem;
    width: 8.0rem;
    margin-top: 2.1rem;
    vertical-align: middle;
    padding: 0.3rem;
    border: none;
    border-radius: 0.5rem;
    margin-right: -0.5rem;
    font-size: 1.3rem; }

input.search-button {
    height: 1.7rem;
    width: 1.7rem;
    margin-top: 2.1rem;
    vertical-align: middle;
    background-image: url('/icons/search-icon.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 2.0rem 2.0rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer; }

.search-summary {
    font-size: 1.1rem; }

.grid-inverse {
    padding-top: 70px; }

@media screen and (min-width: 769px) {
  [class*='grid-'] {}
  .grid-inverse {
    padding-top: 120px;
    // border-style: solid
 } }    // border-color: red

@media screen and (min-width: 1113px) {
  [class*='grid-'] {}
  .grid-inverse {
    padding-top: 100px; } }
